import React, { Component, Fragment } from "react";

import { connect } from "react-redux";
import imgPrincipal from "../../img/imgPrincipal.png";
import * as HomeAction from "../../actions/TareasAction";
import "./Styles.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import Spinner from "../../General/Spinner";
import Fatal from "../../General/Fatal";
import Success from "../../General/Success";
import { Redirect } from "react-router-dom";



class PrestamosModelo extends Component {


  
  async componentDidMount() {
    const {
      consultar_prestamos,
    } = this.props
    consultar_prestamos();
 
  }

  componentDidUpdate() {

    console.log(this.props)
  }

  componentWillUnmount() {
    const { researchtrue } = this.props;
    researchtrue();
  }

 
  mostrarAccion = () => {
    const { error, cargando, success } = this.props;
    if (cargando) {
      return <Spinner />;
    }

    if (success) {
      return <Success mensaje={success} />;
    }

    if (error) {
      return <Fatal mensaje={error} />;
    }
    return false;
  };




  ponerReporteDia = () => {

    const { prestamos,

    } = this.props
    const ponerInfoDia = () => prestamos.map((infoModelo, key) => (

      <tr>
        <td>{infoModelo.fecha}</td>
        <td><textarea disabled>{infoModelo.concepto}</textarea></td>
        <td>{infoModelo.cantidad}</td>
    
      </tr>



    ))

    return (ponerInfoDia())
  }



  render() {


    return (
      <Fragment>
        <div className="main-content fade-in ">
          <img
            className="imgPrincipal"
            alt="imagen principal"
            src={imgPrincipal}
          />
          {!window.localStorage.getItem("tokenModel") ? <Redirect to="/Home" /> : ""}

          <div className="container-fluid">
            <div className="row">

              <div className="col-md-12 ">

                <h2 style={{ textAlign: "center" }}>MIS PRESTAMOS</h2>

                <table className="table table-striped tabla-global" >
                  <thead>
                    <tr>
                      <th>FECHA </th>
                      <th>CONCEPTO</th>
                      <th>CANTIDAD </th>


                    </tr>
                  </thead>
                  <tbody>
                    {this.ponerReporteDia()}
                  </tbody>
                </table>
              </div>


            </div>
          </div>

        </div>
      </Fragment>
    );
  }
}

//conectar tareas al reducer y traer las acciones del tareas actions
const mapStateToProps = reducers => {
  return reducers.TareasReducer;
};
//conectar tareas al reducer y traer las acciones del tareas actions
export default connect(mapStateToProps, HomeAction)(PrestamosModelo);
