import {combineReducers} from 'redux'

import LoginReducer from './LoginReducer'
import HomeReducer from './HomeReducer'
import TareasReducer from './TareasReducer'



export default combineReducers({
    LoginReducer,
    HomeReducer,
    TareasReducer,
    
})