import {
  CARGANDO,
  ERROR,
  RESEARCHTRUE,
  CAMBIO_CONCEPTO,
  CONSULTAR_TAREAS_REALIZADAS,
  RELOAD,
  CONTAR_TAREAS,
  IMAGEN_PERFIL,
  SUCCESS,
  CAMBIO_PASSWORD,
  CAMBIO_NEW_PASSWORD,
  CAMBIO_IMG_PERFIL,
  CAMBIO_USER_NAME,
  CAMBIO_FACTURA,
  NOMBRE_FECHA_PAGOS,
  CAMBIO_DIA_DIA,
  CAMBIO_CUENTAS_CHATURBATE,
  CAMBIO_CUENTAS_CAM4,
  CAMBIO_CUENTAS_BONGA,
  CAMBIO_CUENTAS_STRIP,
  CAMBIO_CUENTAS_STREMATE,
  CONSULTAR_FECHAS_PAGOS,CAMBIO_FECHA_PAGOS,
  CAMBIO_FECHA1,
  CAMBIO_FECHA2,
  CONSULTAR_PRESTAMOS,
  CONSULTAR_MULTAS,
  CONSULTAR_MODELO_DOLAR,
  CONSULTAR_MODELO_DOLAR_GANADO,
  CONSULTAR_MODELO_DOLAR_FALTANTE,
  CONSULTAR_MODELO_PORCENTAJE_ACTUAL,
  CONSULTAR_MODELO_PORCENTAJE_SIGUIENTE,
  CAMBIO_CUENTAS_FLIRT
} from "../types/TareasTypes";

const INITIAL_STATE = {
  factura: [],
  dia_dia: [],
  ListaFechasPagos: [],
  prestamos: [],
  multas: [],
  fechas_pagos_id:'',
  factura_nombre: "",
  dolares:'',
  dolaresGanados:'',
  dolaresFaltantes:'',
  porcentajeActual:'',
  porcentajeSiguiente:'',
  cuentasChaturbate: [],
  cuentasStripchat: [],
  cuentasStreamate: [],
  cuentasBonga: [],
  cuentasCam4: [],
  cuentasFlirt: [],
  tareasc: 0,
  fecha1:'',
  fecha2:'',
  cargando: false,
  error: "",
  research: "",
  success: "",
  perfil_img: "",
  username: "",
  password: "",
  newPassword: "",
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
 
    case CARGANDO:
      return { ...state, cargando: true };

    case ERROR:
      return { ...state, error: action.payload, cargando: false, success: "" };

    case SUCCESS:
      return { ...state, success: action.payload, cargando: false, error: "" };


      case CONSULTAR_FECHAS_PAGOS:
        return {
            ...state,
            ListaFechasPagos: action.payload,
            cargando: false,
            research: false,
        }

        case CAMBIO_FECHA_PAGOS:
            return {
                ...state,
                fechas_pagos_id: action.payload,
                cargando: false,
                research: false,


            }

            case CONSULTAR_PRESTAMOS:
              return {
                  ...state,
                  prestamos: action.payload,
                  cargando: false,
                  research: false,
  
  
              }

              case CONSULTAR_MULTAS:
                return {
                    ...state,
                    multas: action.payload,
                    cargando: false,
                    research: false,
    
    
                }
              
            
    case IMAGEN_PERFIL:
      return {
        ...state,
        perfil_img: action.payload,
        cargando: false,
        research: false,
      };
    case CAMBIO_FACTURA:
      return {
        ...state,
        factura: action.payload,
        cargando: false,
        research: false,
      };
    case CAMBIO_DIA_DIA:
      return {
        ...state,
        dia_dia: action.payload,
        cargando: false,
        research: false,
        error: "",
      };
    case CAMBIO_CUENTAS_CHATURBATE:
      return {
        ...state,
        cuentasChaturbate: action.payload,
        cargando: false,
        research: false,
      };
    case CAMBIO_CUENTAS_STRIP:
      return {
        ...state,
        cuentasStripchat: action.payload,
        cargando: false,
        research: false,
      };
    case CAMBIO_CUENTAS_STREMATE:
      return {
        ...state,
        cuentasStreamate: action.payload,
        cargando: false,
        research: false,
      };
    case CAMBIO_CUENTAS_CAM4:
      return {
        ...state,
        cuentasCam4: action.payload,
        cargando: false,
        research: false,
      };
      case CAMBIO_CUENTAS_BONGA:
        return {
          ...state,
          cuentasBonga: action.payload,
          cargando: false,
          research: false,
        };
      

      case CAMBIO_CUENTAS_FLIRT:
        return {
          ...state,
          cuentasFlirt: action.payload,
          cargando: false,
          research: false,
        };
      
    case NOMBRE_FECHA_PAGOS:
      return {
        ...state,
        factura_nombre: action.payload,
        cargando: false,
        research: false,
      };

    case CAMBIO_PASSWORD:
      return {
        ...state,
        password: action.payload,
      };
    case CAMBIO_NEW_PASSWORD:
      return {
        ...state,
        newPassword: action.payload,
      };

    case CAMBIO_IMG_PERFIL:
      return {
        ...state,
        perfil_img: action.payload,
      };

    case CAMBIO_USER_NAME:
      return {
        ...state,
        username: action.payload,
      };

      case CAMBIO_FECHA1:
        return {
            ...state,
            fecha1: action.payload,

        }

    case CAMBIO_FECHA2:
        return {
            ...state,
            fecha2: action.payload,

        }


    case CONSULTAR_TAREAS_REALIZADAS:
      return {
        ...state,
        vertareas: action.payload,
        cargando: false,
        research: false,
      };
    case CONTAR_TAREAS:
      return {
        ...state,
        tareasc: action.payload,
        cargando: false,
        research: false,
      };

    case CAMBIO_CONCEPTO:
      return {
        ...state,
        concepto: action.payload,
      };

      case CONSULTAR_MODELO_DOLAR:
        return {
          ...state,
          dolares: action.payload,
        };

        case CONSULTAR_MODELO_DOLAR_GANADO:
          return {
            ...state,
            dolaresGanados: action.payload,
          };

          case CONSULTAR_MODELO_DOLAR_FALTANTE:
            return {
              ...state,
              dolaresFaltantes: action.payload,
            };

            case CONSULTAR_MODELO_PORCENTAJE_ACTUAL:
              return {
                ...state,
                porcentajeActual: action.payload,
              };

              case CONSULTAR_MODELO_PORCENTAJE_SIGUIENTE:
                return {
                  ...state,
                  porcentajeSiguiente: action.payload,
                };

    case RESEARCHTRUE:
      return {
        ...state,

        tareas: [],
        vertareas: [],
        cargando: false,
        error: "",
        concepto: "",
        research: "",
        success: "",
        perfil_img: "",
        logo: "",
        trm: "",
      };

    case RELOAD:
      return {
        ...state,

        cargando: false,
        research: true,
      };
    default:
      return state;
  }
};
