import axios from 'axios'
import { CARGANDO, ERROR, CAMBIO_FACTURA,RESEARCHTRUE,CAMBIO_FECHA1,CAMBIO_FECHA2,CAMBIO_IMG_PERFIL,CAMBIO_USER_NAME ,RELOAD,IMAGEN_PERFIL,CAMBIO_PASSWORD,CAMBIO_NEW_PASSWORD,
    SUCCESS,NOMBRE_FECHA_PAGOS,CAMBIO_DIA_DIA,CAMBIO_CUENTAS_CHATURBATE,CAMBIO_CUENTAS_CAM4,CAMBIO_CUENTAS_BONGA,CAMBIO_CUENTAS_STRIP,CAMBIO_CUENTAS_STREMATE,CONSULTAR_FECHAS_PAGOS,CAMBIO_FECHA_PAGOS,
    CONSULTAR_PRESTAMOS,CONSULTAR_MULTAS,CONSULTAR_MODELO_DOLAR,CONSULTAR_MODELO_DOLAR_GANADO,CONSULTAR_MODELO_DOLAR_FALTANTE,
    CONSULTAR_MODELO_PORCENTAJE_ACTUAL,CONSULTAR_MODELO_PORCENTAJE_SIGUIENTE,CAMBIO_CUENTAS_FLIRT} from '../types/TareasTypes'


    import {URL} from '../../src/General/url'





export const updataeProfile = (data) => async(dispatch)=>{
   
    
    dispatch({

        type: CARGANDO
        
    
    })

    try {
      
        let json = JSON.stringify(data)
        let params = 'json='+json
       
        let headers = {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': window.localStorage.getItem('tokenModel')
        }
        const respuesta = await axios.put(URL+`updateMonitorProfilePicture`, params,{
            headers: headers
        })
     
if(respuesta.data.status==="Success"){
    dispatch({

        type: SUCCESS,
        payload: respuesta.data.message
        
    
    })
 
   
}else{
    dispatch({

        type: ERROR,
        payload: respuesta.data.message
        
    
    })
}
        
 /*   const {prestamos} = getState().PrestamoGlobalReducer getState 
        */
        
        
        
    } catch (error) {
        
        if(error.message==="Request failed with status code 401"){
            window.localStorage.setItem('tokenModel','')
            window.localStorage.setItem('userData','')
        }else{
    dispatch({

        type: ERROR,
        payload: 'Intente mas tarde - Cuenta Ocupada'
        
    
    })
    }
}

 
}


export const UpdatePassword = (data) => async(dispatch)=>{
   
    
    dispatch({

        type: CARGANDO
        
    
    })

    try {
      
        let json = JSON.stringify(data)
        let params = 'json='+json
       
        let headers = {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': window.localStorage.getItem('tokenModel')
        }
        const respuesta = await axios.put(URL+`updateMonitorinformation`, params,{
            headers: headers
        })
     
if(respuesta.data.status==="Success"){
    dispatch({

        type: SUCCESS,
        payload: respuesta.data.message
        
    
    })
    dispatch({

        type: RELOAD,
        
    
    })
   
}else{
    dispatch({

        type: ERROR,
        payload: respuesta.data.message
        
    
    })
}
        
 /*   const {prestamos} = getState().PrestamoGlobalReducer getState 
        */
        
        
        
    } catch (error) {
        
        if(error.message==="Request failed with status code 401"){
            window.localStorage.setItem('tokenModel','')
            window.localStorage.setItem('userData','')
        }else{
    dispatch({

        type: ERROR,
        payload: 'Intente mas tarde - Cuenta Ocupada'
        
    
    })
    }
}

 
}


export const guardarimagen = (data) => async (dispatch) => {




    dispatch({
  
        type: CARGANDO
  
  
    })
  
    try {
  
  
        let params = data
        let headers = {
            'Content-Type': 'multipart/from-data',
            'Authorization': window.localStorage.getItem('tokenModel')
        }
        const respuesta = await axios.post(URL + 'StoreImage', params, {
            headers: headers
        })
  
        dispatch({
          type: IMAGEN_PERFIL,
          payload: respuesta.data.imagename
        });
      
  
      /*   dispatch({
            type: CAMBIO_FOTO_PERFIL,
            payload: respuesta.data.imagename
        }) */
  
    } catch (error) {
  
  
        if (error.message === "Request failed with status code 401") {
            window.localStorage.setItem('tokenModel', '')
            window.localStorage.setItem('userData', '')
        } else {
            dispatch({
  
                type: ERROR,
                payload: error.message
  
  
            })
        }
    }
  }

  export const consultar_factura = (data) => async (dispatch) => {




    dispatch({
  
        type: CARGANDO
  
  
    })
  
    try {
  
  
       
        let json = JSON.stringify(data)
        let params = 'json='+json
        let headers = {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': window.localStorage.getItem('tokenModel')
        }
        const respuesta = await axios.post(URL + 'billsDetails', params, {
            headers: headers
        })
  
        if(respuesta.data.status==="Success"){
          


            dispatch({
        
                type: CAMBIO_FACTURA,
                payload: respuesta.data.ultimo_pago
                
            
            })


            
            dispatch({
        
                type: CAMBIO_DIA_DIA,
                payload: respuesta.data.dia_dia
                
            
            })

            dispatch({
        
                type: NOMBRE_FECHA_PAGOS,
                payload: respuesta.data.ultimo_pago.fecha_pago_nomina_modelo.fecha
                
            
            })

    


        }else if(respuesta.data.status==="Error"){
 
            dispatch({
  
                type: ERROR,
                payload: respuesta.data.message
  
  
            })

        }
    
    } catch (error) {
  
  
        if (error.message === "Request failed with status code 401") {
            window.localStorage.setItem('tokenModel', '')
            window.localStorage.setItem('userData', '')
        } else {
            dispatch({
  
                type: ERROR,
                payload: error.message
  
  
            })
        }
    }
  }

  export const consultar_dia_dia = (data) => async (dispatch) => {




    dispatch({
  
        type: CARGANDO
  
  
    })
  
    try {
  
  
       
        let json = JSON.stringify(data)
        let params = 'json='+json
        let headers = {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': window.localStorage.getItem('tokenModel')
        }
        const respuesta = await axios.post(URL + 'billsDetailsDay', params, {
            headers: headers
        })
  
        if(respuesta.data.status==="Success"){
          


            dispatch({
        
                type: CAMBIO_FACTURA,
                payload: respuesta.data.ultimo_pago
                
            
            })


            
            dispatch({
        
                type: CAMBIO_DIA_DIA,
                payload: respuesta.data.dia_dia
                
            
            })

            dispatch({
        
                type: NOMBRE_FECHA_PAGOS,
                payload: respuesta.data.fechas
                
            
            })

    


        }else if(respuesta.data.status==="Error"){
 
            dispatch({
  
                type: ERROR,
                payload: respuesta.data.message
  
  
            })

        }
    
    } catch (error) {
  
  
        if (error.message === "Request failed with status code 401") {
            window.localStorage.setItem('tokenModel', '')
            window.localStorage.setItem('userData', '')
        } else {
            dispatch({
  
                type: ERROR,
                payload: error.message
  
  
            })
        }
    }
  }
  


export const getUser = () => async(dispatch)=>{
    

    try {

     
    
        let headers = {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': window.localStorage.getItem('tokenModel')
        }
        const respuesta = await axios.get(URL+`getMyInformation`,{
            headers: headers
        })
    


        if(respuesta.data.status==="Success"){
            dispatch({
        
                type: CAMBIO_IMG_PERFIL,
                payload: respuesta.data.modelo.foto_perfil
                
            
            })

            dispatch({
        
                type: CAMBIO_USER_NAME,
                payload: respuesta.data.modelo.nick_name
                
            
            })


            dispatch({
        
                type: CAMBIO_FACTURA,
                payload: respuesta.data.ultimo_pago
                
            
            })


            
            dispatch({
        
                type: CAMBIO_DIA_DIA,
                payload: respuesta.data.dia_dia
                
            
            })

            dispatch({
        
                type: NOMBRE_FECHA_PAGOS,
                payload: respuesta.data.ultimo_pago.fecha_pago_nomina_modelo.fecha
                
            
            })



        }
    

        
        
    } catch (error) {
        
        if(error.message==="Request failed with status code 401"){
            window.localStorage.setItem('tokenModel','')
            window.localStorage.setItem('userData','')
        }else{
      /*       dispatch({

                type: ERROR,
                payload: 'Error Modelo'
                
            
            }) */
        }
    }
}



export const getMyGoal = () => async(dispatch)=>{
    

    try {

     
    
        let headers = {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': window.localStorage.getItem('tokenModel')
        }
        const respuesta = await axios.get(URL+`getModelDolarsWeekGoals`,{
            headers: headers
        })
    


        if(respuesta.data.status==="Success"){
            dispatch({
        
                type: CONSULTAR_MODELO_DOLAR,
                payload: respuesta.data.dolares
                
            
            })

            dispatch({
        
                type: CONSULTAR_MODELO_DOLAR_GANADO,
                payload: respuesta.data.dolaresGanados
                
            
            })


            dispatch({
        
                type: CONSULTAR_MODELO_DOLAR_FALTANTE,
                payload: respuesta.data.dolaresFaltantes
                
            
            })

            dispatch({
        
                type: CONSULTAR_MODELO_PORCENTAJE_ACTUAL,
                payload: respuesta.data.porcentajeActual
                
            
            })

            dispatch({
        
                type: CONSULTAR_MODELO_PORCENTAJE_SIGUIENTE,
                payload: respuesta.data.porcentajeSiguiente
                
            
            })



        }
    

        
        
    } catch (error) {
        
        if(error.message==="Request failed with status code 401"){
            window.localStorage.setItem('tokenModel','')
            window.localStorage.setItem('userData','')
        }else{
      /*       dispatch({

                type: ERROR,
                payload: 'Error Modelo'
                
            
            }) */
        }
    }
}

export const getUserAcc= () => async(dispatch)=>{
    

    try {

     
    
        let headers = {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': window.localStorage.getItem('tokenModel')
        }
        const respuesta = await axios.get(URL+`getMyAccount`,{
            headers: headers
        })
    


        if(respuesta.data.status==="Success"){
           

            dispatch({
        
                type: CAMBIO_CUENTAS_CHATURBATE,
                payload: respuesta.data.cuentaChatur
                
            
            })

            
            dispatch({
        
                type: CAMBIO_CUENTAS_STRIP,
                payload: respuesta.data.cuentaStrip
                
            
            })


            dispatch({
        
                type: CAMBIO_CUENTAS_STREMATE,
                payload: respuesta.data.cuentaStremate
                
            
            })



            dispatch({
        
                type: CAMBIO_CUENTAS_CAM4,
                payload: respuesta.data.cuentacam4
                
            
            })

            
            dispatch({
        
                type: CAMBIO_CUENTAS_BONGA,
                payload: respuesta.data.cuentasBonga
                
            
            })

            dispatch({
        
                type: CAMBIO_CUENTAS_FLIRT,
                payload: respuesta.data.cuentaflirt
                
            
            })
            


        }
    

        
        
    } catch (error) {
        
        if(error.message==="Request failed with status code 401"){
            window.localStorage.setItem('tokenModel','')
            window.localStorage.setItem('userData','')
        }else{
            dispatch({

                type: ERROR,
                payload: 'Intente mas tarde'
                
            
            })
        }
    }
}


export const cambioPassword  = (value) => async(dispatch,) => {
    dispatch({

        type: CAMBIO_PASSWORD,
        payload: value
        
    
    })
    
};



export const cambiofecha1  = (fecha1) => async(dispatch,) => {
    dispatch({

        type: CAMBIO_FECHA1,
        payload: fecha1
        
    
    })
    
};

export const cambiofecha2  = (fecha2) => async(dispatch,) => {
    dispatch({

        type: CAMBIO_FECHA2,
        payload: fecha2
        
    
    })
    
};

export const cambioNewPassword  = (value) => async(dispatch,) => {
    dispatch({

        type: CAMBIO_NEW_PASSWORD,
        payload: value
        
    
    })
    
};




export const researchtrue  = () => (dispatch,) => {
    dispatch({

        type: RESEARCHTRUE
        
    
    })


    

   
};
export const cambioFechaPagos = (id_pago) => (dispatch) => {
    dispatch({
        type: CAMBIO_FECHA_PAGOS,
        payload: id_pago
  
    })
  }

export const consultar_fechas_pagos = () => async (dispatch) => {


    try {
  
  
        let headers = {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': window.localStorage.getItem('tokenModel')
        }
        const respuesta = await axios.get(URL + 'getPaymentDateModel', {
            headers: headers
        })
  
  
  
  
        dispatch({
            type: CONSULTAR_FECHAS_PAGOS,
            payload: respuesta.data.data
        })
  
    } catch (error) {
  
  
        if (error.message === "Request failed with status code 401") {
            window.localStorage.setItem('token', '')
            window.localStorage.setItem('userData', '')
        } else {
            dispatch({
  
                type: ERROR,
                payload: error.message
  
  
            })
        }
    }
  }


  export const consultar_prestamos = () => async(dispatch)=>{
    

    try {

     
    
        let headers = {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': window.localStorage.getItem('tokenModel')
        }
        const respuesta = await axios.get(URL+`getModelPlataformLoans`,{
            headers: headers
        })
    


        if(respuesta.data.status==="Success"){
            dispatch({
        
                type: CONSULTAR_PRESTAMOS,
                payload: respuesta.data.data
                
            
            })

      


        }
    

        
        
    } catch (error) {
        
        if(error.message==="Request failed with status code 401"){
            window.localStorage.setItem('tokenModel','')
            window.localStorage.setItem('userData','')
        }else{
            dispatch({

                type: ERROR,
                payload: 'Intente mas tarde'
                
            
            })
        }
    }
}


export const consultar_multas = () => async(dispatch)=>{
    

    try {

     
    
        let headers = {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': window.localStorage.getItem('tokenModel')
        }
        const respuesta = await axios.get(URL+`getModelPlataformFines`,{
            headers: headers
        })
    


        if(respuesta.data.status==="Success"){
            dispatch({
        
                type: CONSULTAR_MULTAS,
                payload: respuesta.data.data
                
            
            })

      


        }
    

        
        
    } catch (error) {
        
        if(error.message==="Request failed with status code 401"){
            window.localStorage.setItem('tokenModel','')
            window.localStorage.setItem('userData','')
        }else{
            dispatch({

                type: ERROR,
                payload: 'Intente mas tarde'
                
            
            })
        }
    }
}