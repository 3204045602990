import axios from 'axios'
import {CARGANDO,ERROR,CAMBIO_USUARIO_ID,CAMBIO_TITULO,GUARDAR,LIMPIAR,TOKENVERIFY,CERRAR_SESION,TRAER_TODAS,PERMISOS} from '../types/LoginTypes'
import {URL} from '../../src/General/url'


//acciones para tareas 


export const cambioUsuarioId = (usuario_id) => (dispatch)=>{
    dispatch({
        type: CAMBIO_USUARIO_ID,
        payload: usuario_id
        
    })


}


export const cambioTitulo = (titulo) => (dispatch)=>{
    dispatch({
        type: CAMBIO_TITULO,
        payload: titulo
        
    })
}


export const logOut = () => (dispatch)=>{

    
    dispatch({
        type: CERRAR_SESION,
       
})
    window.localStorage.setItem('tokenModel','')
}


export const comprobartoken = () => (dispatch,getState)=>{
    //const { Login } = getState().LoginReducer;
    if(!window.localStorage.getItem('tokenModel') ){

        dispatch({
            type: TOKENVERIFY,
           
    })

    }else{

       
        dispatch({
            type: GUARDAR,
           
    })
    }
   
  
}


export const agregar = (nueva_tarea) => async(dispatch)=>{
    
    dispatch({

        type: CARGANDO
        
    
    })

    try {
        let json = JSON.stringify(nueva_tarea)
        let params = 'json='+json
        const respuesta = await axios.post(URL+'loginModel', params)
       

        if(respuesta.data.token.status === 'error'){
            window.localStorage.setItem('tokenModel','')
            dispatch({

                type: ERROR,
                payload: respuesta.data.token.message
         
            
            })

        }else{
            if(respuesta.data.status === 'success'){
  

                
                window.localStorage.setItem('tokenModel',respuesta.data.token)
                
                dispatch({

                    type: TRAER_TODAS,
                   
             
                
                })
                dispatch({
                    type: PERMISOS,
                    payload: respuesta.data.rol,
                   
                    })
                    
                
            }
    dispatch({
        type: GUARDAR,
        payload: respuesta.data,
       
        })
        }
    

        
    } catch (error) {
        
            window.localStorage.setItem('tokenModel','')
            window.localStorage.setItem('userData','')
         
    dispatch({

        type: ERROR,
        payload: 'Login Incorrecto'
        
    
    })
    }
}



export const limpiarForma  = () => async(dispatch,) => {
    dispatch({

        type: LIMPIAR
        
    
    })

   
};