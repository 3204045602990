import React from 'react'
import '../css/spinner.css'

const Success = (props) => (

    <div className="alert alert-success" role="alert">
  { props.mensaje}
</div>
 
)

export default Success