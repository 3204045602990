export const CONSULTAR = 'tareas_traer_todas';
export const CONSULTAR_USUARIO = 'tareas_traer_usuario';
export const CARGANDO = 'tareas_cargando';
export const ERROR = 'tareas_error';
export const GUARDAR = 'tareas_guardada';
export const ACTUALIZAR = 'tareas_actualizar';
export const LIMPIAR = 'tareas_limpiar';
export const RESEARCHTRUE = 'tareas_research';
export const SUCCESS = 'tareas_succes';
export const CAMBIO_FECHAPRESTAMO = 'tareas_fecha';
export const CAMBIO_CANTIDAD = 'tareas_cantidad';
export const CAMBIO_CONCEPTO = 'tareas_concepto';
export const CAMBIO_FECHA1 = 'tareas_fecha1';
export const CAMBIO_FECHA2 = 'tareas_fecha2';
export const FILTRAR_PRESTAMOS_MINIMOS = 'tareas_minimos_filtrar';
export const CONSULTAR_TAREAS_REALIZADAS = 'tareas_traer_realizadas';
export const RELOAD = 'tareas_reload';
export const CONTAR_TAREAS = 'tareas_contar';
export const IMAGEN_PERFIL = 'tareas_perfil_imagen';
export const CAMBIO_PASSWORD = 'tareas_cambio_password';
export const CAMBIO_NEW_PASSWORD = 'tareas_cambio_new_password';
export const CAMBIO_IMG_PERFIL = 'auth_img_perfil';
export const CAMBIO_PERMISOS = 'auth_permisos_user';
export const CAMBIO_USER_NAME = 'auth_nombre_user';
export const CAMBIO_DIA_DIA = 'plataforma_dia_dia';
export const IMAGEN_PERFIL_LOGO = 'auth_img_perfil_logo';
export const CAMBIO_FACTURA = 'plataforma_modelos_factura';
export const NOMBRE_FECHA_PAGOS = 'plataforma_modelos_nombre_fecha_pagos';
export const CAMBIO_CUENTAS_CHATURBATE = 'plataforma_modelos_cuentas_modelo_chaturbate';
export const CAMBIO_CUENTAS_STRIP = 'plataforma_modelos_cuentas_modelo_strip';
export const CAMBIO_CUENTAS_STREMATE = 'plataforma_modelos_cuentas_modelo_streamate';
export const CAMBIO_CUENTAS_CAM4 = 'plataforma_modelos_cuentas_modelo_cam4';
export const CAMBIO_CUENTAS_BONGA = 'plataforma_modelos_cuentas_modelo_bonga';
export const CAMBIO_CUENTAS_FLIRT = 'plataforma_modelos_cuentas_modelo_flirt';
export const CONSULTAR_FECHAS_PAGOS = 'plataforma_modelos_fecha_de_pagos';
export const CAMBIO_FECHA_PAGOS = 'plataforma_modelos_fecha_id_pagos';
export const CONSULTAR_PRESTAMOS = 'plataforma_modelos_conuslta_prestamos';
export const CONSULTAR_MULTAS = 'plataforma_modelos_conuslta_multas';
export const CONSULTAR_MODELO_DOLAR = 'plataforma_modelos_consultar_dolares';
export const CONSULTAR_MODELO_DOLAR_GANADO = 'plataforma_modelos_consultar_dolares_ganados';
export const CONSULTAR_MODELO_DOLAR_FALTANTE = 'plataforma_modelos_consultar_dolares_faltantes';
export const CONSULTAR_MODELO_PORCENTAJE_ACTUAL = 'plataforma_modelos_consultar_porcentaje_actual';
export const CONSULTAR_MODELO_PORCENTAJE_SIGUIENTE = 'plataforma_modelos_consultar_porcentaje_siguiente';










