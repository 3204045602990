import React, { Component, Fragment } from "react";

import { connect } from "react-redux";
import imgPrincipal from "../../img/imgPrincipal.png";
import * as HomeAction from "../../actions/TareasAction";
//import "./Styles.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import { Redirect } from "react-router-dom";


//var source = new EventSource('http://localhost/API-Studiotopmedellin/public/messageEvent');

class PrestamosModelo extends Component {

  
  componentDidMount() {
 
  }

  componentDidUpdate() {
 
  }

  componentWillUnmount() {

  }
  
  render() {


    return (
      <Fragment>
        <div className="main-content fade-in ">
          <img
            className="imgPrincipal"
            alt="imagen principal"
            src={imgPrincipal}
          />
          {!window.localStorage.getItem("tokenModel") ? <Redirect to="/Home" /> : ""}

          <div className="container-fluid">
            <div className="row">

              <div className="col-md-12 ">

        
              </div>


            </div>
          </div>

        </div>
      </Fragment>
    );
  }
}

//conectar tareas al reducer y traer las acciones del tareas actions
const mapStateToProps = reducers => {
  return reducers.TareasReducer;
};
//conectar tareas al reducer y traer las acciones del tareas actions
export default connect(mapStateToProps, HomeAction)(PrestamosModelo);
