import React, { Component } from 'react'
import { BrowserRouter, Route } from 'react-router-dom'
import Menu from './menu'
import * as LoginAction from '../actions/LoginAction'
import Login from './Login'
import Prestamos from './Prestamos'
import Multas from './Multas'
import Home from './Home'
import ObsPage from './ObsPage'

import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'





class App extends Component {

  componentDidMount() {
    const { comprobartoken } = this.props
    comprobartoken()


  }

  render() {


    return (
      <BrowserRouter>

        {/*divpara las dar stilos a el menu*/}
       

          {

            this.props.isAuth
              ? <Route >
                <Menu />
                <Route exact path='/Home' component={Home} />
                <Route exact path='/Prestamos' component={Prestamos} /> 
                <Route exact path='/Multas' component={Multas} /> 
                <Route exact path='/ObsPage' component={ObsPage} /> 
                
                {/*      <Route exact path='/publicaciones/:key' component={Publicaciones}/>
                <Route exact path='/tareas/guardar' component={TareasGuardar}/>
                <Route exact path='/tareas/guardar/:usu_id/:tar_id' component={TareasGuardar}/> */}
              </Route>
              : <Route>
               <Route exact path='/' component={Login} />
              </Route>



          }

          {

            this.props.isAuth ? <Redirect to='/Home' /> : <Redirect to='/' />
          }
   


      </BrowserRouter>

    )
  }
}

const mapStateToProps = ({ LoginReducer }) => LoginReducer
//conectar tareas al reducer y traer las acciones del login actions
export default connect(mapStateToProps, LoginAction)(App)