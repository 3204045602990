import React, { Component, Fragment } from "react";

import { connect } from "react-redux";
import imgPrincipal from "../../img/imgPrincipal.png";
import cuentasCBtnImg from "../../img/cuentasCBtnImg.png";
import cuentasF4BtnImg from "../../img/cuentasF4BtnImg.png";
import cuentasSBtnImg from "../../img/cuentasSBtnImg.png";
import cuentasSTCtnImg from "../../img/cuentasSTCtnImg.png";
import cuentasBBtnImg from "../../img/cuentasBBtnImg.png";
import cuentasC4BtnImg from "../../img/cuentasC4BtnImg.png";
import miMetaIcon from "../../img/miMetaIcon.png";
import misDolaresIcon from "../../img/misDolaresIcon.png";
import miProcentajeIcon from "../../img/miProcentajeIcon.png";
import * as HomeAction from "../../actions/TareasAction";
import "./Styles.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import Spinner from "../../General/Spinner";
import Fatal from "../../General/Fatal";
import Success from "../../General/Success";
import { Redirect } from "react-router-dom";
import DatePicker from "react-date-picker";
import Modal from "react-bootstrap/Modal";

class Home extends Component {
  state = {
    show: false,
    showModalCookies: false,
    cuentas: [],
    dateStart: new Date(),
    dateEnd: new Date(),
    keyCookie: "",
    pcNameCookie: "",
  };

  showModal = (e, data) => {
    this.setState({ show: true, cuentas: data });
  };

  hideModal = () => {
    this.setState({ show: false });
    this.setState({ showModalCookies: false });
  };

  async componentDidMount() {
    const { consultar_fechas_pagos, getUserAcc, getMyGoal } = this.props;
    consultar_fechas_pagos();
    getUserAcc();
    getMyGoal();
    this.cambiofecha1(this.state.dateStart);
    this.cambiofecha2(this.state.dateEnd);
    this.commandEvent();

    this.crearCookies();
    this.obtenerCookies();
  }

  commandEvent() {
    document.addEventListener("keydown", (e) => {
      if (e.ctrlKey && e.shiftKey && e.which === 121) {
        this.setState({ showModalCookies: true });
        e.preventDefault();
      }
    });
  }

  componentDidUpdate() {
    console.log(this.props);
  }

  componentWillUnmount() {
    const { researchtrue } = this.props;
    researchtrue();
  }

  consultar_factura = (e) => {
    e.preventDefault();
    const { consultar_factura, fechas_pagos_id } = this.props;
    const data = {
      fechas_pagos_nomina_modelos_id: fechas_pagos_id,
    };
    consultar_factura(data);
  };

  crearCookies() {
    let year = new Date();
    year = year.getFullYear();

    var expiresdate = new Date(year + 2, 1, 2, 11, 20);
    var cookievalue = "Hola Mundo!";
    document.cookie =
      "testcookieModelos=" +
      encodeURIComponent(cookievalue) +
      "; expires=" +
      expiresdate.toUTCString();
  }

  obtenerCookies() {
    var lasCookies = document.cookie;
    console.log("cookies", lasCookies);
  }

  consultar_dia_dia = (e) => {
    e.preventDefault();
    const { consultar_dia_dia, fecha1, fecha2 } = this.props;
    const data = {
      fecha1: fecha1,
      fecha2: fecha2,
    };
    consultar_dia_dia(data);
  };

  cambiofecha1 = (date) => {
    this.setState({ dateStart: date });
    var fechaIncioString = "";
    if (date) {
      fechaIncioString = `${date.getFullYear()}-${
        date.getMonth() + 1
      }-${date.getDate()}`;
    } else {
      fechaIncioString = "";
    }

    this.props.cambiofecha1(fechaIncioString);
  };

  cambiofecha2 = (date) => {
    this.setState({ dateEnd: date });
    var fechaFinalString = "";
    if (date) {
      fechaFinalString = `${date.getFullYear()}-${
        date.getMonth() + 1
      }-${date.getDate()}`;
    } else {
      fechaFinalString = "";
    }
    this.props.cambiofecha2(fechaFinalString);
  };

  cambioTareas = (event) => {
    this.props.cambioTareas(event.target.value);
  };
  mostrarAccion = () => {
    const { error, cargando, success } = this.props;
    if (cargando) {
      return <Spinner />;
    }

    if (success) {
      return <Success mensaje={success} />;
    }

    if (error) {
      return <Fatal mensaje={error} />;
    }
    return false;
  };

  mostrarCuentas = () => {
    const ponerInfoDia = () =>
      this.state.cuentas.map((infoModelo, key) => (
        <tr key={key}>
          <td>{infoModelo.nombre}</td>
          <td>{infoModelo.passwordc} </td>
        </tr>
      ));

    return ponerInfoDia();
  };

  fechas_dia = () => {
    return (
      <div>
        <form onSubmit={(e) => this.handleSubmit(e)}>
          <fieldset className="form-fieldset">
            <div>
              <DatePicker
                onChange={this.cambiofecha1}
                value={this.state.dateStart}
              />
            </div>
            <div>
              <DatePicker
                onChange={this.cambiofecha2}
                value={this.state.dateEnd}
              />
            </div>
            {/*                 <div className=" form-input select__places ">
                {this.listaLugares()}
            </div> */}

            <div className="btn__reportes_div">
              <button
                className="btn__reportes__dia__modelo"
                type="submit"
                onClick={this.consultar_dia_dia}
              >
                Ver Reporte
              </button>
            </div>
          </fieldset>
        </form>
      </div>
    );
  };

  cambioFechaPagos = (event) => {
    this.props.cambioFechaPagos(event.target.value);
  };

  listaFechasPagos = () => {
    const { ListaFechasPagos } = this.props;

    const ponerOpcionesFechas = () =>
      ListaFechasPagos.map((fechas, key) => (
        <option className="option-values" key={key} value={fechas.id}>
          {fechas.fecha}
        </option>
      ));

    return (
      <select
        className="lista__fechas__nomina__modelos_plataforma"
        name="select"
        onChange={this.cambioFechaPagos}
        required
      >
        <option className="option-values" value="Fechas De Pagos">
          Fechas De Pagos
        </option>
        {ponerOpcionesFechas()}
      </select>
    );
  };

  fecha_pagos = () => {
    return (
      <div>
        <form onSubmit={(e) => this.handleSubmit(e)}>
          <fieldset className="form-fieldset">
            <div className=" form-input select__places__models ">
              {this.listaFechasPagos()}
            </div>

            <div className="btn__reportes_div">
              <button
                className="btn__reportes__dia__modelo"
                type="submit"
                onClick={this.consultar_factura}
              >
                Ver Reporte
              </button>
            </div>
          </fieldset>
        </form>
      </div>
    );
  };

  ponerReporte = () => {
    const { factura } = this.props;

    return (
      <tbody>
        <tr>
          <td>Chaturbate</td>
          <td>{factura.suma_chaturbate ? factura.suma_chaturbate * 20 : 0}</td>
          <td>${factura.dolar}</td>
          <td>${factura.suma_chaturbate}</td>
        </tr>
        <tr>
          <td>Stripchat</td>
          <td>{factura.suma_strip * 20}</td>
          <td></td>
          <td>${factura.suma_strip}</td>
        </tr>
        <tr>
          <td>Cam4</td>
          <td></td>
          <td></td>
          <td>${factura.suma_cam4}</td>
        </tr>
        <tr>
          <td>Streamate</td>
          <td></td>
          <td></td>
          <td>${factura.suma_stremate}</td>
        </tr>
        <tr>
          <td>Paypal</td>
          <td></td>
          <td></td>
          <td>${factura.suma_paypal}</td>
        </tr>
        <tr>
          <td>BongaCams</td>
          <td>{factura.suma_jasmin }</td>
          <td></td>
          <td>${factura.suma_jasmin}</td>
        </tr>
        <tr>
          <td>Flirt4Free</td>
          <td></td>
          <td></td>
          <td>${factura.suma_flirt}</td>
        </tr>
        <tr>
          <td>Total Dólares</td>
          <td></td>
          <td></td>
          <td>${factura.suma_total_plataformas}</td>
        </tr>
        {factura.dolares_modelo ? (
          <tr>
            <td>Porcentaje {factura.porcentaje_pago * 100}%</td>
            <td></td>
            <td></td>
            <td>${factura.dolares_modelo}</td>
          </tr>
        ) : (
          ""
        )}
        {factura.dolar > 0 ? (
          <tr>
            <td>Total Pesos</td>
            <td></td>
            <td></td>
            <td>${factura.dolares_modelo * factura.dolar}</td>
          </tr>
        ) : (
          ""
        )}
        {factura.arriendo_nomina ? (
          <tr>
            <td>Arriendo</td>
            <td></td>
            <td></td>
            <td>$-{factura.arriendo_nomina}</td>
          </tr>
        ) : (
          ""
        )}
        {factura.suma_prestamos ? (
          <tr>
            <td colspan="2">
              <strong>Préstamos</strong>
            </td>
          </tr>
        ) : (
          ""
        )}

        {factura.suma_prestamos
          ? factura.Prestamo_modelo.map((prestamo_modelo, key) => (
              <Fragment>
                <tr>
                  <td>{prestamo_modelo.concepto}</td>
                  <td></td>
                  <td></td>
                  <td>{`${prestamo_modelo.cantidad}  (${new Date(
                    prestamo_modelo.fecha_prestamo
                  ).toLocaleDateString("en-US", {
                    timeZone: "America/New_York",
                  })})`}</td>
                </tr>
              </Fragment>
            ))
          : ""}

        {factura.suma_prestamos ? (
          <tr>
            <td>Total Préstamos</td>
            <td></td>
            <td></td>
            <td>${factura.suma_prestamos ? -factura.suma_prestamos : 0}</td>
          </tr>
        ) : (
          ""
        )}

        {factura.suma_servicios ? (
          <tr>
            <td colspan="2">
              <strong>Servicios</strong>
            </td>
          </tr>
        ) : (
          ""
        )}

        {factura.suma_servicios
          ? factura.servicios_modelo.map((servicios_modelo, key) => (
              <Fragment>
                <tr>
                  <td>{servicios_modelo.nombre}</td>
                  <td></td>
                  <td></td>
                  <td>{servicios_modelo.cantidad}</td>
                </tr>
              </Fragment>
            ))
          : ""}

        {factura.suma_servicios ? (
          <tr>
            <td>Total Servicios</td>
            <td></td>
            <td></td>
            <td>${factura.suma_servicios ? -factura.suma_servicios : 0}</td>
          </tr>
        ) : (
          ""
        )}
        {factura.suma_multas ? (
          <tr>
            <td>Multas</td>
            <td></td>
            <td></td>
            <td>${factura.suma_multas ? -factura.suma_multas : 0}</td>
          </tr>
        ) : (
          ""
        )}

        {factura.prestamog_nomina > 0 ? (
          <Fragment>
            <tr>
              <td>Deuda restante</td>
              <td></td>
              <td></td>
              <td>
                ${factura.prestamog_nomina ? factura.prestamog_nomina : 0}
              </td>
            </tr>
            <tr>
              <td>Cuota</td>
              <td></td>
              <td></td>
              <td>${factura.cuota ? -factura.cuota : 0}</td>
            </tr>
            <tr>
              <td>Debe de la deuda</td>
              <td></td>
              <td></td>
              <td>${factura.prestamog_nomina - factura.cuota}</td>
            </tr>
          </Fragment>
        ) : (
          ""
        )}

        <tr>
          <td>Total</td>
          <td></td>
          <td></td>
          <td>$ {Math.round(factura.total_pesos)}</td>
        </tr>
      </tbody>
    );
  };

  ponerReporteDia = () => {
    const { dia_dia } = this.props;
    const ponerInfoDia = () =>
      dia_dia.map((infoModelo, key) => (
        <tr>
          <td>{infoModelo.fecha_diaria.fecha}</td>
          <td>{infoModelo.tokens_plataforma1}</td>
          <td>{infoModelo.tokens_plataforma5}</td>
          <td>{infoModelo.tokens_plataforma3}</td>
          <td>{infoModelo.tokens_plataforma4}</td>
          <td>{infoModelo.tokens_plataforma7}</td>
          <td>{infoModelo.tokens_plataforma2}</td>
          <td>{infoModelo.tokens_plataforma8}</td>
        </tr>
      ));

    return ponerInfoDia();
  };

  ponerReporteGoal = () => {};

  render() {
    return (
      <Fragment>
        <div className="main-content fade-in ">
          <img
            className="imgPrincipal"
            alt="imagen principal"
            src={imgPrincipal}
          />
          {!window.localStorage.getItem("tokenModel") ? (
            <Redirect to="/Home" />
          ) : (
            ""
          )}

          <div className="container-fluid">
            <div className="row">
              <div className=" reporte__div__principal__modelos">
                <div className="row">
                  <div className="col-md-4 ">
                    <h2 style={{ textAlign: "center" }}>DÓLARES</h2>
                    <img
                      alt="imagen para chaturbate cuentas"
                      className="img__iconos__porcentajes"
                      src={miMetaIcon}
                    />
                    <h2 style={{ textAlign: "center" }}>
                      {this.props.dolares}
                    </h2>
                  </div>

                  <div className="col-md-4 ">
                    <h2 style={{ textAlign: "center" }}>PORCENTAJE</h2>
                    <img
                      alt="imagen para chaturbate cuentas"
                      className="img__iconos__porcentajes"
                      src={misDolaresIcon}
                    />
                    <h2
                      style={{ textAlign: "center" }}
                    >{`${this.props.porcentajeActual}%`}</h2>
                    <h2 style={{ textAlign: "center" }}>
                      {this.props.dolaresGanados}
                    </h2>
                  </div>

                  <div className="col-md-4 ">
                    <h2 style={{ textAlign: "center" }}>PRÓXIMA META</h2>
                    <img
                      alt="imagen para chaturbate cuentas"
                      className="img__iconos__porcentajes"
                      src={miProcentajeIcon}
                    />
                    <h2
                      style={{ textAlign: "center" }}
                    >{`${this.props.porcentajeSiguiente}% -${this.props.dolaresFaltantes} USD FALTANTES`}</h2>
                  </div>
                </div>
              </div>

              <div className="col-md-6 ">
                <h2 style={{ textAlign: "center" }}>MI DIA DIA</h2>
                {this.fechas_dia()}
              </div>
              <div className="col-md-6 ">
                <h2 style={{ textAlign: "center" }}>MIS FACTURAS</h2>
                {this.fecha_pagos()}
              </div>

              <div className="col-md-12 ">
                {this.mostrarAccion()}
                <h2 style={{ textAlign: "center" }}>
                  {this.props.factura_nombre}
                </h2>

                <table className="table table-striped tabla-global">
                  <thead>
                    <tr>
                      <th> </th>
                      <th>TOKENS</th>
                      <th>DOLAR </th>
                      <th>TOTAL </th>
                    </tr>
                  </thead>

                  {this.ponerReporte()}
                </table>
              </div>

              <div className="col-md-12 ">
                <h2 style={{ textAlign: "center" }}>MI INFORME DIARIO</h2>

                <table className="table table-striped tabla-global">
                  <thead>
                    <tr>
                      <th>FECHA </th>
                      <th>CHATURBATE</th>
                      <th>STRIPCHAT </th>
                      <th>STREMATE </th>
                      <th>CAM4 </th>
                      <th>PAYPAL </th>
                      <th>BONGA</th>
                      <th>FLIRT4FREE </th>
                    </tr>
                  </thead>
                  <tbody>{this.ponerReporteDia()}</tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-3">
                <img
                  alt="imagen para chaturbate cuentas"
                  className="icon__plataform"
                  onClick={(e) =>
                    this.showModal(e, this.props.cuentasChaturbate)
                  }
                  src={cuentasCBtnImg}
                />
              </div>
              <div className="col-md-3">
                <img
                  alt="imagen para stripchat cuentas"
                  className="icon__plataform"
                  onClick={(e) =>
                    this.showModal(e, this.props.cuentasStripchat)
                  }
                  src={cuentasSBtnImg}
                />
              </div>
              <div className="col-md-3">
                <img
                  alt="imagen para stremate cuentas"
                  className="icon__plataform"
                  onClick={(e) =>
                    this.showModal(e, this.props.cuentasStreamate)
                  }
                  src={cuentasSTCtnImg}
                />
              </div>

              <div className="col-md-3">
                <img
                  alt="imagen para stremate cuentas"
                  className="icon__plataform"
                  onClick={(e) =>
                    this.showModal(e, this.props.cuentasBonga)
                  }
                  src={cuentasBBtnImg}
                />
              </div>
              
              <div className="col-md-3">
                <img
                  alt="imagen para cam4 cuentas"
                  className="icon__plataform"
                  onClick={(e) => this.showModal(e, this.props.cuentasCam4)}
                  src={cuentasC4BtnImg}
                />
              </div>
              <div className="col-md-3">
                <img
                  alt="imagen para cam4 cuentas"
                  className="icon__plataform"
                  onClick={(e) => this.showModal(e, this.props.cuentasFlirt)}
                  src={cuentasF4BtnImg}
                />
              </div>

              <Modal show={this.state.show} onHide={this.hideModal}>
                <Modal.Header closeButton>
                  <Modal.Title>Mis cuentas</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <table className="table table-striped tabla-global">
                    <thead>
                      <tr>
                        <th>Cuenta </th>
                        <th>Contaseña</th>
                      </tr>
                    </thead>
                    <tbody>{this.mostrarCuentas()}</tbody>
                  </table>
                </Modal.Body>
                <Modal.Footer>
                  <button
                    onClick={this.hideModal}
                    className="btn__modal btn-secondary"
                  >
                    cerrar
                  </button>
                </Modal.Footer>
              </Modal>

              <Modal show={this.state.showModalCookies} onHide={this.hideModal}>
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                  <input
                    style={{ width: "100%" }}
                    type="text"
                    value={this.state.keyCookie}
                    onChange={(e) =>
                      this.setState({ keyCookie: e.target.value })
                    }
                    placeholder="Ingrese la key de la plataforma"
                  />
                  <input
                    style={{ width: "100%" }}
                    type="text"
                    value={this.state.pcNameCookie}
                    onChange={(e) =>
                      this.setState({ pcNameCookie: e.target.value })
                    }
                    placeholder="Ingrese el nombre del pc"
                  />
                </Modal.Body>
                <Modal.Footer>
                  <button
                    onClick={this.hideModal}
                    className="btn__modal btn-secondary"
                  >
                    cerrar
                  </button>
                </Modal.Footer>
              </Modal>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

//conectar tareas al reducer y traer las acciones del tareas actions
const mapStateToProps = (reducers) => {
  return reducers.TareasReducer;
};
//conectar tareas al reducer y traer las acciones del tareas actions
export default connect(mapStateToProps, HomeAction)(Home);
